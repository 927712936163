<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  carrier: {
    type: Object,
    required: true,
  },
});

const navItems = (id) => {
  return [
    {
      text: "Details",
      icon: "mdi-file-outline",
      to: `/carriers/${id}`,
    },
    {
      text: "Available Orders",
      icon: "mdi-format-list-bulleted",
      to: `/carriers/${id}/available-orders`,
    },
    {
      text: "Pending Orders",
      icon: "mdi-receipt-clock-outline",
      to: `/carriers/${id}/pending-orders`,
    },
    {
      text: "Users",
      icon: "mdi-account-group",
      to: `/carriers/${id}/users`,
    },
  ];
};

</script>

<template>
  <v-card flat class="d-flex flex-wrap mx-4 border">
    <div class="pl-4 py-4 w-100 bg-surface border-b d-flex align-center">
      <a class="text-h7" href="/carriers">Carriers</a>
      <span class="px-2">//</span>
      <span class="text-h6">{{ carrier?.name }} - {{ title }}</span>
    </div>
    <div class="w-100 d-flex">
      <div class="w-25 overflow-y-auto border-e-sm">

        <v-card flat class="mx-auto pa-2 w-100">
          <div class="text-center" v-if="!carrier">
            <v-progress-circular
              :width="8"
              :size="50"
              color="primary"
              class="mb-16"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list>
            <v-list-item
              exact
              v-if="carrier"
              v-for="(item, i) in navItems(carrier.id)"
              :key="i"
              :value="item"
              :to="item.to"
              color="primary"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
        <v-divider></v-divider>
      </div>
      <div class="w-75 overflow-y-auto">
        <slot />
      </div>
    </div>
  </v-card>
</template>

<style scoped>
@media (max-width: 768px) {
  .v-card .mx-auto {
    max-width: 75px;
  }
}
</style>
